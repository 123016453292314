.card {
    display: flex;
    gap: 20px;
    padding: 16px 20px;
    border-radius: 16px;
    background: #111;
    position: relative;

}

.lbx {
    width: 40%;
}

.content {}

.title {
    font-size: 14px;
    margin-bottom: 6px;
}

.description {
    font-size: 12px;
    line-height: 135%;
    color: #b4b4b4;
}

.action {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
}

.button {
    padding: 6px 13px;
    width: fit-content;
    font-size: 12px;
}

.price {
    font-size: 14px;
}



/* Контейнер задаёт перспективу для 3D трансформаций */
.heart-container {
    perspective: 800px;
    display: inline-block;
}

/* Применяем анимацию вращения и внешнюю тень */
.heart {
    transform-style: preserve-3d;
    transform-origin: center;
    animation: rotateHeart 5s infinite linear;
    /* Внешняя тень для эффекта «парения» */
    filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.3));
}

@keyframes rotateHeart {
    0% {
        transform: rotateY(0deg) rotateX(0deg);
    }

    25% {
        transform: rotateY(90deg) rotateX(10deg);
    }

    50% {
        transform: rotateY(180deg) rotateX(0deg);
    }

    75% {
        transform: rotateY(270deg) rotateX(-10deg);
    }

    100% {
        transform: rotateY(360deg) rotateX(0deg);
    }
}