.loader_icon {
  content: "";
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mainContainer {
  position: relative;
}

/* .mainContainer::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;

  height: 80%;
  width: 100%;
  top: 50%;

  transform: translateY(-50%);
  opacity: 1;

  background: url('../../../../assets/923.jpg');
  background-position: center;
  background-size: cover;


} */



.container {
  padding-top: 22px;
  position: relative;
}

.backGradient {
  border-radius: 35px;
  width: 40vw;
  height: 30vw;
  /* filter: blur(50px); */
  margin: 42px auto 0 auto;
  position: absolute;
  top: -20px;
  /* z-index: -1; */
  pointer-events: none;
  will-change: transform, opacity;
}

.header {
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}

.button {
  cursor: pointer;
  font-size: 24px;
  color: white;
  background: transparent;
  border: none;
  font-family: slick;
}

.cardInfo {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  gap: 10px;
}

.cardInfo_item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  flex: 1;
  padding: 20px 12px 12px;
  border-radius: 12px;
}

.item_title {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.6);
}

.item_text {
  font-weight: 400;
  font-size: 30px;
  line-height: 100%;
  color: #fff;
}

.slider_image {
  position: relative;
  padding: 0 20px 12px;
  display: flex;
  justify-content: center;
}

.imageNotExist__container {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageNotExist {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;

  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
}

.duck {
  transform: translateX(-20px);
}

/* .imageNotExist img{
  max-width: 40px;
  max-height: 40px;
} */

.slider__navigation {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 0;
  width: 100%;
  z-index: 2;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.sliderImage {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-enter-right {
  animation: slideInRight 0.5s forwards;
  z-index: -1;
}

.slide-enter-left {
  animation: slideInLeft 0.5s forwards;
  z-index: -1;
}

.videocard {
  max-height: 30vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.buttonPallette {
  position: absolute;
  bottom: -22px;
  right: 20px;
  z-index: 2;
}


.backGradientPizza {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: url('https://t4.ftcdn.net/jpg/02/52/87/53/360_F_252875338_y1vdA6q91Hjk3lQJhFnTq5vld7E4QzIF.jpg'); */
  background-position: center;
  height: 90%;
  width: 100%;
  top: 0;


  /* transform: translateY(-50%); */
  opacity: 1;

  background: url('../../../../assets/923.jpg');
  background-position: center;
  background-size: cover;

  border-radius: 8px;
  width: calc(100% - 32px);
  left: 16px;
}