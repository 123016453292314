.card {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 8px 16px 12px;
    border-radius: 16px;
    /* background: #111; */

    /* background: radial-gradient(rgba(0, 149, 236, 0.267) 10%, #111 10%); */

    /* background: #111; */

    background-size: 15px 15px;
    -webkit-animation: bscale 30s infinite linear;
    animation: bscale 30s infinite linear;




    /* background: radial-gradient(rgba(255, 255, 255, 0.6) 10%, #430ccf 10%); */
    /* background-size: 15px 15px;
    -webkit-animation: bscale 10s infinite linear;
    animation: bscale 10s infinite linear; */


    /* background-image: url("data:image/svg+xml,%3Csvg width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%230097EC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 0h35v35H0V0zm5 5h25v25H5V5zm5 5h15v15H10V10zm5 5h5v5h-5v-5zM40 5h25v25H40V5zm5 5h15v15H45V10zm5 5h5v5h-5v-5zM70 35H35v35h35V35zm-5 5H40v25h25V40zm-5 5H45v15h15V45zm-5 5h-5v5h5v-5zM30 40H5v25h25V40zm-5 5H10v15h15V45zm-5 5h-5v5h5v-5z'/%3E%3C/g%3E%3C/svg%3E"); */
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

}

.lbx {
    width: 30vw;
    height: calc(30vw/1.25);
}

.content {}

.title {
    font-size: 14px;
    margin-bottom: 6px;
}

.description {
    font-size: 10px;
    line-height: 135%;
    color: #b4b4b4;
}

.action {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
}

.button {
    padding: 6px 13px;
    width: fit-content;
    font-size: 12px;
}

.price {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
}


@-webkit-keyframes bscale {
    0% {
        background-position: 0px 0px;
    }

    100% {
        background-position: 100% 100%;
    }
}


@keyframes bscale {
    0% {
        background-position: 0px 0px;
    }

    100% {
        background-position: 100% 100%;
    }
}



.containContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 4px;
    margin-bottom: 20px;
}

.containContainer .container {
    background: rgba(255, 255, 255, 0.1);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    gap: 6px;

}

.containContainer .container::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}