.infoSection {
    display: flex;
    flex-direction: column;
    /* padding: 20px 16px; */
    /* padding-bottom: 200px; */
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--bg-secondary-color);
}

.infoSectionContainer {
    margin: 40px 16px 24px;
}

.header {
    padding: 18px 16px 10px;
    display: flex;
    justify-content: space-between;
}

.claimButton {
    padding: 8px 42px !important;
    border-radius: 8px !important;
    font-size: 14px !important;

    width: fit-content !important;
    height: fit-content !important;
}

.booster {
    padding: 14px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.miningInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.subheader {
    font-weight: 700;
    font-size: 12px;
    color: #9f9f9f;
}

.subheader .separator {
    margin: 0 4px;
}


.miningInfo__mined {
    font-weight: 700;
    font-size: 20px !important;
    color: #fff;
    font-size: 14px;
}

.miningInfo__mined span {
    font-size: 12px;

}

.miningInfo__ghs {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.booster_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.booster_line .left {
    display: flex;
    gap: 10px;
    align-items: center;
}

.booster_line .right {
    display: flex;
    gap: 16px;
    align-items: center;
}


.booster__percent {
    font-weight: 700;
    font-size: 12px;
    color: #9f9f9f;
    text-align: right;
}

.booster__receive {
    font-weight: 700;
    font-size: 12px;
    width: 60px;
    color: #fff;
    text-align: right;
}

.booster__name {
    font-weight: 700;
    font-size: 12px;
    color: #9f9f9f;
}

.booster__percent {
    font-weight: 700;
    font-size: 12px;
    color: #9f9f9f;
}

.booster__value {
    font-weight: 700;
    font-size: 12px;
    color: #fff;
}

.infoCard {
    background: #272727;
    display: flex;
    gap: 7px;
    border-radius: 8px;
    padding: 14px 12px;
    align-items: center;
}

.infoCard_image {
    height: 60px;
}

.infoCard_content {}

.infoCard__name {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}

.infoCard__name span {
    font-size: 12px;
    color: #bfbfbf;
}

.infoCard__ghs {
    font-weight: 400;
    font-size: 10px;
    color: #bfbfbf;
}

.infoCard__money {
    margin-top: 6px;

    font-weight: 500;
    font-size: 12px;
    color: #0097ec;

    border-radius: 4px;
    padding: 2px 4px;
    background: rgba(0, 151, 236, 0.1);
}

.infoCard__money span {
    font-size: 10px;
}

.sliderLeft {
    animation: slideRight 0.6s normal;
    opacity: 1;
}

.sliderRight {
    animation: slideLeft 0.6s normal;
    opacity: 1;
}

@keyframes slideLeft {
    0% {
        opacity: 1;
    }

    50% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50.01% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
    }

    50% {
        transform: translateX(100%);
        opacity: 0;
    }

    50.01% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.info_buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 27px;
}

.total__span {
    font-size: 12px;
    font-weight: 500;
    color: #b4b4b4;
}

.cardSlider {
    padding: 0 40px;
    position: relative;
    overflow: hidden;
}

.arrow {
    height: 21px;
    width: 21px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #939393;
cursor: pointer;
    position: absolute;

}

.arrowLeft {
    left: 11px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
}

.arrowRight {
    right: 11px;
    top: 50%;
    transform: translateY(-50%);

}


.premiumIcon {
    position: relative;
    width: 22px;
    height: 22px;
}

.premiumIcon .notActiveBg {
    color: #b4b4b4 !important;
}

.premiumIcon .notActiveContent {
    color: var(--bg-secondary-color);
}

.premiumIcon .bg {
    width: 100%;
    height: 100%;
    color: var(--primary-color);

}

.premiumIcon .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    transform: translateX(-50%) translateY(-50%);

}


.pool__icon {
    border-radius: 22px;
    width: 22px;
    height: 22px;
    background: #b4b4b4;
    color: var(--bg-secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

/* --------------------------------------------------------------------------------------------------------------- */
.storeButton {
    font-weight: 700;
    border-radius: 10px;
    padding: 12px 20px;
    flex-shrink: 0;
    width: fit-content;
    flex: 1;
}


.filtersButton {
    font-weight: 400;
    font-size: 16px;

    color: #fff;
    gap: 6px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.timer {
    font-size: 14px;
    text-align: center;
    margin-top: 4px;
    color: #b4b4b4;
}

.collectButton {
    display: flex;
    gap: 4px;
    align-items: baseline;
}



.blob {
    padding: 0 !important;
    width: fit-content !important;
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    background: var(--primary-color);
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateY(-50%) translateX(-50%) scale(1);
    left: 50%;

    box-shadow: 0 0 0 0 rgba(0, 151, 236, 1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(0.95);
        left: 50%;

        box-shadow: 0 0 0 0 rgba(0, 151, 236, 0.5);
    }

    70% {
        transform: translateY(-50%) translateX(-50%) scale(1);
        left: 50%;
        box-shadow: 0 0 0 10px rgba(0, 151, 236, 0);
    }

    100% {
        transform: translateY(-50%) translateX(-50%) scale(0.95);
        left: 50%;
        box-shadow: 0 0 0 0 rgba(0, 151, 236, 0);
    }
}






.blob {
    padding: 0 !important;
    width: fit-content !important;
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    background: var(--primary-color);
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    transform: translateY(-50%) translateX(-50%) scale(1);
    box-shadow: 0 0 0 0 rgba(0, 151, 236, 1);
    animation: pulse 2s infinite;
    transition: background-color 0.4s ease-in-out;
  }
  
  .animated {
    background: #7ed321  !important; /* Цвет при анимации */
    animation: pulse-active 2s infinite; /* Новый цвет пульса */
  }
  
  .checkmark {
    width: 20px;
    height: 20px;
    visibility: hidden;
    transform: translateY(3px) translateX(-1px);
  }
  
  .show {
    visibility: visible;
  }
  
  .checkmarkPath {
    stroke: white;
    stroke-width: 7;
    stroke-linecap: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: drawCheck 0.6s 0.5s forwards; /* Галочка рисуется после 0.5с */
  }
  
  @keyframes pulse {
    0% {
      transform: translateY(-50%) translateX(-50%) scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 151, 236, 0.5);
      left: 50%;

    }
    70% {
      transform: translateY(-50%) translateX(-50%) scale(1);
      box-shadow: 0 0 0 10px rgba(0, 151, 236, 0);
      left: 50%;

    }
    100% {
      transform: translateY(-50%) translateX(-50%) scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 151, 236, 0);
      left: 50%;

    }
  }
  
  @keyframes pulse-active {
    0% {
      transform: translateY(-50%) translateX(-50%) scale(0.95);
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.5);
      left: 50%;

    }
    70% {
      transform: translateY(-50%) translateX(-50%) scale(1);
      box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
      left: 50%;

    }
    100% {
      transform: translateY(-50%) translateX(-50%) scale(0.95);
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
      left: 50%;

    }
  }
  
  @keyframes drawCheck {
    to {
      stroke-dashoffset: 0;
    }
  }

  