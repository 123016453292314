.container {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0;
    z-index: 999;
}

.nav {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    justify-content: space-between;
    padding: 20px 20px 30px;
    background: rgba(0, 0, 0, .47);
    border-radius: 20px 20px 0 0;

    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: rgba(255, 255, 255, .3);

    -webkit-tap-highlight-color: transparent;
    outline: none;
    cursor: pointer;
}

.active {
    color: #fff !important
}

.button__icon {}

.button__text {
    font-weight: 700;
    font-size: 10px;
    line-height: 114%;
    text-align: center;
}