.container {
    display: flex;
    gap: 18px;
}

.logoContainer {
    border: 0.50px solid #484849;
    border-radius: 8px;
    padding: 4px;
    width: 69px;
    height: 69px;
    background: #1c1c1e;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer img {
    width: 80%;
    height: 80%;
}

.modalLogoContainer {
    background: rgba(255, 255, 255, 0.15);
    border: 0.50px solid #e7e0ae;
    border-radius: 8px;
    width: 100px;
    height: 100px;
}

.container .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.container .right .options {
    display: flex;
    gap: 12px;
}

.container .right .options .option {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.container .right .options .option .header {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #b4b4b4;
}

.container .right .options .option .data {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #f0f0f0;
}

.container .right .options .buttonContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.container .right .options .buttonContainer .button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    background: var(--primary-color);
    border-radius: 6px;

    font-weight: 600;
    font-size: 12px;
    color: #fff;
    /* margin-left: 14px; */

    cursor: pointer;
    width: 100px;
}

.coinBalance {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #fff;

    display: flex;
    gap: 4px;
    align-items: flex-end;
    height: 100%;
}

.coinBalance span {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #b4b4b4;
}

.coinBalance .stakeReceiveContainer {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-left: 14px;
    padding-top: 4px;
}

.coinBalance .stakeReceiveContainer .stakedReceive {
    color: #00ec56;
    background: #00ec5623;
    border-radius: 4px;
    padding: 2px 5px;

    vertical-align: top;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    /* width: 100%; */
    width: 100px;


}

.coinReceive {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.coinReceive .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #c4bbbb;
}

.coinReceive .amount {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #0097ec;
}

.coinReceive .amount span {
    font-weight: 500;
    font-size: 14px;
}