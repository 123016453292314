.container {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 0;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
}

.container::-webkit-scrollbar {
    display: none;
}

.countLine {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

}

.count__header {
    font-weight: 600;
}

.countController {
    display: flex;
    gap: 20px;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.countButton {
    background: rgba(255, 255, 255, 0.15);
    font-size: 22px;
    line-height: 2px;
    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    font-weight: 700;
    cursor: pointer;
}

.countButton_minus {
    background: rgba(255, 255, 255, 0.05);
}

.countButton.active {
    background: var(--primary-color);
}

.countButton.countButton_minus.active {
    background: rgba(0, 151, 236, 1);
}

.count {
    font-size: 22px;
    font-weight: 700;
}

.payment {
    display: flex;
    gap: 10px;
}


.paymentButton_ton {
    background: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.paymentButton_ton_connect {
    position: absolute;
    bottom: 100%;
    color: var(--primary-color);
    background: rgb(0, 151, 236, 0.1);
    width: 95%;
    padding: 2px 0;
    text-align: center;
    border-radius: 4px;

}

.paymentButton_gift {
    width: fit-content !important;
    padding: 12px 20px !important;
}

.paymentButton_coin {
    background: #fff;
    background: var(--primary-color);
    color: #fff;
    /* background: rgba(255, 255, 255, .8); */
    /* color: #1c1c1c; */
    border-radius: 8px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;

    position: relative;
    height: 50px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;


    font-size: 12px;


    /* ------ */

}

.bt_line {
    display: flex;
    align-items: center;
    gap: 6px;
}

.bt_line .payments {
    display: flex;
    align-items: center;
    /* gap: 4px; */
}

.bt_line .payments div {
    display: flex;
    align-items: center;
    gap: 2px;
}

.supported {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #b4b4b4;
    margin-top: 5px;
    justify-content: center;
}

.supported .payments {
    display: flex;
    align-items: center;
    gap: 4px;
}

.supported .payments div {
    display: flex;
    align-items: center;
    gap: 0px;
}

.paymentButton_coin_container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
}

.paymentButton_coin .coin {
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    /* height: 60px;
    width: 60px; */
    height: 60px;
    width: 60px;
    animation: rotate 5s infinite;
}

.swapButton {
    position: absolute;
    right: 10px;
    background: rgba(255, 255, 255, 0.3);
    padding: 4px;
    border-radius: 4px;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.swapIcon {
    width: 16px;
    height: 16px;
}

@keyframes rotate {
    0% {
        transform: translateX(0) rotate(0deg);
    }

    50% {
        transform: translateY(-25%) translateX(0) rotate(10deg);
    }

    100% {
        transform: translateX(0) rotate(0deg);
    }
}

.summaryInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* gap: 20px; */
    background: var(--bg-secondary-color);
    margin: 40px 16px 0;
    padding: 16px 16px 8px;
    border-radius: 12px;

}

.paymentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 20px 16px 0;
    padding: 0;
}

.boostersContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* gap: 20px; */
    background: var(--bg-secondary-color);
    margin: 20px 16px 0;
    padding: 16px 16px;
    border-radius: 12px;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 6px 0 30px;
}

.option {
    display: flex;
    padding: 16px 12px;
    gap: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    transition: 0.1s all ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
}

.option_selected {
    /* border-color: rgba(53, 231, 229, 0.5); */
    /* background: rgba(53, 231, 229, 0.4); */
    box-shadow: 0 0 16px 0 #1c9d85;
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 1);
}

.option_iconContainer {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
    /* background: rgba(0, 146, 192, 0.5); */
    border-radius: 8px;
    flex-shrink: 0;
    /* background: rgba(53, 231, 229, 0.); */
}

.option_description {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-bottom: 10px;
    transition: 0.1s all ease-in-out;
}

.option_description__selected {
    color: rgba(255, 255, 255, 0.9);
}

.option_price__coin {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(255, 255, 255, 0.2);
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}

.option_price__ton {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(0, 152, 234, 0.5);
    color: #fff;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}


.modalSubheader {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    font-weight: 700;
}

.modalBalance {
    font-weight: 400;
    font-size: 14px;
    line-height: 89%;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 12px;
}

.boosters_header {
    font-size: 14px;
}

.boosters {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0 0;
}






/* 0097EC */

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-left-color: #fff;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}













.premiumSection {
    perspective: 1000px;
    border: 0.5px solid #ECA900;
    background: radial-gradient(65.76% 548.4% at 6.18% 50%, rgba(236, 169, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.premiumSection .left {
    display: flex;
    align-items: center;
    gap: 14px;
}

.premiumIconHolder {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform-style: preserve-3d;
    perspective-origin: center;
}

/* Основной SVG-элемент */
.premiumIconHolder .premiumIcon {
    /* color: var(--primary-color); */
    color: #FFC800;
    transform-origin: center;
    position: absolute;
    z-index: 15;
}

/* Дублируем иконку для создания объемных граней */
.premiumIconHolder .shadow {
    position: absolute;
    color: #b68f00;
    transform-origin: center;

}

.premiumIconHolder .shadow:nth-child(1) {
    transform: translateZ(-0.5px);
}

.premiumIconHolder .shadow:nth-child(2) {
    transform: translateZ(-1px);
}

.premiumIconHolder .shadow:nth-child(3) {
    transform: translateZ(-1.5px);
}

.premiumIconHolder .shadow:nth-child(4) {
    transform: translateZ(-2px);
}

.premiumIconHolder .shadow:nth-child(5) {
    transform: translateZ(-2.5px);
    color: #FFC800;
}


/* Анимация вращения */
.premiumIconHolder {
    animation: rotate3D 3s infinite linear;
}

@keyframes rotate3D {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

.premiumSection .left .description {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.premiumSection .left .description h3 {
    font-weight: 500;
    font-size: 14px;
    color: #eca900;
    margin: 0;
}

.premiumSection .left .description p {
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    color: rgba(255, 200, 0, 0.8);
    margin: 0;
}


.payments_methods {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.payments_methods__description {
    font-size: 12px;
    line-height: 1.6;
    color: #b4b4b4;
    margin-top: 12px;
    margin-bottom: 16px;
    text-align: center;
}

.paymentButton {
    padding: 24px !important;
    border-radius: 8px !important;
    /* border: 0.1px solid #b4b4b4; */
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    background: rgba(255, 255, 255, 0.05) !important;

    cursor: pointer !important;
}

.paymentButton_Minex {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.paymentButton .title {
    font-weight: 500;
    color: #b4b4b4;
    font-size: 14px;
}

.paymentButton .value {
    /* color: #FFC800; */
    display: flex;
    align-items: center;
    gap: 6px;

    font-size: 12px;
}

.paymentButton.stars {
    /* border-color: #FFC800;
    background: rgb(255, 200, 0, 0.05); */

}

.paymentButton.stars .title {
    /* color: #FFC800; */
}


.paymentButton.stars .value {
    /* color: #FFC800; */
}


.paymentButton.ton {
    border-color: #0098EA;
    /* background: rgb(0, 152, 234, 0.05);  */
}

.paymentButton.minex {
    /* border-color: #0097ec;
    background: rgb(0, 151, 236, 0.05); */
}