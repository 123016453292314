/* Notification.module.css */
.notification {
  position: fixed;
  top: -100px;
  /* Скрыт за экраном */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 95vw;
  padding: 10px;
  font-size: 14px;
  transition: top 0.5s ease-in-out;
}

.show {
  top: 20px;
  /* Появляется вверху */
}

.hide {
  top: -100px;
  /* Возвращается за экран */
}

/* Разные стили для типов уведомлений */
.content {
  border-radius: 5px;
  /* padding: 8px 15px; */
  /* text-align: center; */
  color: #fff;
  border: 0.8px solid #fff;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 1), 0 4px 6px -4px rgba(0, 0, 0, 1); */
  overflow: hidden;
}

.content.success {
  border-color: #4caf50;
  /* box-shadow: 0 10px 15px -3px rgba(76, 175, 80, .1), 0 4px 6px -4px rgba(76, 175, 80, .1); */
  color: white;
  background: #183125;

}

.content.error {
  border-color: #f44336;
  /* box-shadow: 0 10px 15px -3px rgba(244, 67, 54, .3), 0 4px 6px -4px rgba(244, 67, 54, .3); */
  color: white;
  background: #3C1916;

}

.content.info {
  border-color: #2196f3;
  color: white;
  background: #052A41;

}


.header {
  display: flex;
  justify-content: space-between;
}

.header.success {
  background: #4caf50;
  outline: 0.5px solid #4caf50;
}

.header.error {
  background: #f44336;
  outline: 0.5px solid #f44336;
}

.header.info {
  background: #2196f3;
  outline: 0.5px solid #2196f3;
}

.message {
  padding: 8px 15px;

}

.message.success {
  color: #4caf50;
}

.message.error {
  color: #f44336;

}

.message.info {
  color: #2196f3;

}

.closeButton {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton.success {
  background: #183125;
  outline: 0.5px solid #4caf50;
  color: #4caf50;

}

.closeButton.error {
  background: #3C1916;
  outline: 0.5px solid #f44336;
  color: #f44336;
}

.closeButton.info {
  background: #052A41;
  outline: 0.5px solid #2196f3;
  color: #2196f3;
}


.headerTitle {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 10px;
  padding: 3px 15px;
}

.headerTitle.success,
.titleBg.success {
  color: #183125;

}

.headerTitle.error,
.titleBg.error {
  color: #3C1916;

}

.headerTitle.info,
.titleBg.info {
  color: #052A41;

}


.titleBg{
  height: 16px;
}
.titleRight {
  display: flex;
}