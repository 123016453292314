.premiumModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    /* background: linear-gradient(180deg, #001d2d 0%, #000 100%); */
    /* background:  radial-gradient(124.71% 54.2% at 49.87% 2.64%, rgba(3, 70, 107, 0.5) 0%, rgba(0, 0, 0, 0) 100%), #000; */
    background: #000;

    padding: 20px 25px 24px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    opacity: 0;
    transition: 0.3s all ease-in-out;
}

.premiumModal.show{
opacity: 1;
}

.premiumModal::-webkit-scrollbar{
    display: none;
}

.premiumModal .title {
    font-weight: 500;
    font-size: 24px;
    color: #ededed;
    text-align: center;
    margin-bottom: 20px;
}

.premiumModal .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    color: #b4b4b4;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 50px;
    
}

.premiumModal .headerImage {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.premiumModal .headerImage .blur {
    width: 205px;
    height: 87px;
    filter: blur(40px);
    background: rgba(0, 151, 236, 0.2);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.premiumModal .headerImage .iconHolder {
    height: 25vw;
    width: 25vw;
    position: relative;
}

.premiumModal .headerImage .iconHolder .iconBG {
    color: var(--primary-color);
    animation: rotate 100s linear infinite;
    width: 100%;
    height: 100%;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.premiumModal .headerImage .iconHolder .iconContent {
    position: absolute;
    width: 30%;
    height: 30%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.premiumModal .headerImage .iconHolder .outline_premium_icon {
    position: absolute;
    opacity: 100%;
    color: var(--primary-color);
    opacity: 0.2;
    animation: fadeAndMove 3s infinite ease-in-out;
}

@keyframes fadeAndMove {
    0% {
        opacity: 0.2;
        transform: translateY(0);
    }

    50% {
        opacity: 0.8;
        transform: translateY(-1px);
    }

    100% {
        opacity: 0.2;
        transform: translateY(0);
    }
}

.premiumModal .headerImage .iconHolder .outline_l_1 {
    left: -41px;
    top: 11px;
    opacity: 0.2;
    height: 25px;
    animation-delay: 0.5s;
}

.premiumModal .headerImage .iconHolder .outline_l_2 {
    left: -28px;
    top: 49px;
    opacity: 0.8;
    height: 16px;
    animation-delay: 1s;
}

.premiumModal .headerImage .iconHolder .outline_l_3 {
    left: -12px;
    top: 78px;
    opacity: 0.4;
    height: 25px;
    animation-delay: 2.5s;
}

.premiumModal .headerImage .iconHolder .outline_l_4 {
    left: -48px;
    top: 90px;
    opacity: 0.2;
    height: 16px;
    animation-delay: 1.5s;
}

.premiumModal .headerImage .iconHolder .outline_r_1 {
    right: -14px;
    top: 18px;
    opacity: 0.8;
    height: 25px;
    animation-delay: 4s;
}

.premiumModal .headerImage .iconHolder .outline_r_2 {
    right: -45px;
    top: 39px;
    opacity: 0.2;
    height: 22px;
    animation-delay: 0s;
}

.premiumModal .headerImage .iconHolder .outline_r_3 {
    right: -40px;
    top: 78px;
    opacity: 0.6;
    height: 12px;
    animation-delay: 2s;
}

.premiumModal .headerImage .iconHolder .outline_r_4 {
    right: -5px;
    top: 90px;
    opacity: 0.5;
    height: 16px;
    animation-delay: 1s;
}

.premiumModal .main {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex: 1;
}

.premiumModal .main .premiumModal_options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 0;
    flex: 1;
}

.premiumModal .main .premiumModal_options .premiumModal_option {
    border: 0.50px solid #4e4e4e;
    border-radius: 8px;
    padding: 12px 12px;
    background: var(--bg-secondary-color);

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: 0.1s all ease-in-out;
}

.premiumModal .main .premiumModal_options .premiumModal_option.selected {
    background: rgba(0, 151, 236, 0.06);
    border: 0.50px solid var(--primary-color);
}

.premiumModal .main .premiumModal_options .premiumModal_option .left {
    display: flex;
    align-items: center;
    gap: 13px;
}

.premiumModal .main .premiumModal_options .premiumModal_option .select {
    border: 1px solid #333335;
    border-radius: 100px;
    width: 18px;
    height: 18px;
    transition: 0.1s all ease-in-out;
}

.premiumModal .main .premiumModal_options .premiumModal_option.selected .select {
    border: 6px solid var(--primary-color);
}

.premiumModal .main .premiumModal_options .premiumModal_option .name {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #ededed;
    margin: 0;
}

.premiumModal .main .premiumModal_options .premiumModal_option .price {
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-transform: uppercase;
    text-align: center;
    color: #b4b4b4;
}

.premiumModal .buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* width: 100%; */
    /* position: absolute;
    bottom: 24px;
    left: 0;
    padding: 0 25px; */
}

.premiumModal .backButton {
    background: transparent;
}





.premiumData_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    gap: 3px;
}

.premiumDate {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.premiumIcon {
    position: relative;
    width: 16px;
    height: 16px;
}

.premiumIcon .bg {
    width: 100%;
    height: 100%;
    color: var(--primary-color);

}

.premiumIcon .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    transform: translateX(-50%) translateY(-50%);

}