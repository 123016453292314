.main {
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: scroll;

    padding: 18px 0 24px;
    padding-bottom: 200px;
    max-height: 100vh;
}


.main::-webkit-scrollbar {
    display: none;
}


.settingsLine {
    padding: 0 16px;
    height: 29px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}


.premiumData_container {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.premiumDate {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.premiumIcon {
    position: relative;
    width: 16px;
    height: 16px;
}

.premiumIcon .bg {
    width: 100%;
    height: 100%;
    color: var(--primary-color);

}

.premiumIcon .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    transform: translateX(-50%) translateY(-50%);

}

.premiumPurchase {
    display: flex;
    align-items: center;
    gap: 3px;
    background: rgba(33, 150, 243, 0.15);
    padding: 4px 12px;
    border-radius: 6px;
    border: 0.5px solid #2195f367;
    cursor: pointer;
}

.premiumPurchase .premiumDate {
    color: #2196F3 !important;
    font-weight: 500;
}


.settingsIcon {
    color: var(--primary-color);
    position: absolute;
    right: 16px;
    top: 0
}

.menuContainer {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.header .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header .userInfo .name {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #fff;
    margin-top: 25px;
}

.header .userInfo .stats {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}

.header .userInfo .stats .hashRate {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #b1b1b1;
}

.header .userInfo .stats .position {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #0097ec;
    border-radius: 4px;
    padding: 4px 6px;
    background: rgba(0, 151, 236, 0.13);
}



.poolContainer {
    display: flex;
    padding: 0 16px;
    cursor: pointer;
    text-decoration: none;
}



.pool {
    flex: 1;
    margin-left: 18px;
    padding: 0 16px 0 40px;
    border: 1px dashed #004267;
    background: rgba(0, 151, 236, 0.1);
    border-radius: 8px;
    height: 44px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

}



.pool .avatar {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #004267;

    border: 2px dashed #004267;
    border-radius: 100px;
    width: 46px;
    height: 46px;
    background: #000;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
}

.pool .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #0097ec;
    display: flex;
    align-items: center;
    gap: 6px;
}

.pool .title .av_boost {
    color: #7ed321;
    display: flex;
    align-items: center;
    font-size: 12px;
    background: rgba(126, 211, 33, 0.1);
    padding: 2px 4px;
    border-radius: 4px;
    gap: 2px;
}

.pool .title .av_boost span {
    font-size: 10px;
}

.pool .title .av_boost svg {
    transform: translateY(-3px);
}

.pool.joined {
    background: var(--bg-secondary-color);
    /* border: 0.5px solid #b4b4b4; */
    border: none;


}

.pool.joined .avatar {
    border: none;
}

.pool.joined .title {
    color: #fff;
    font-weight: 500;
}

.userAvatarContainer {
    position: relative;
}

.shadow {
    position: absolute;
    top: 0;
    left: 0;
    /* filter: blur(30px); */
    width: 25vw;
    height: 25vw;
    background: var(--primary-color);

    opacity: 0.8;
    border-radius: 100%;
    filter: blur(10px);

}

.stakingTitleStyle {}

.stakingMenuStyle {
    background: rgb(86, 59, 1, 0.8) !important;
    /* border: 0.40px solid #ffc802 !important; */
}


.lootboxesMenuStyle {
    background: rgb(139, 1, 239, 0.3) !important;
    /* border: 0.40px solid #8b01ef !important; */
}