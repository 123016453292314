.overlay {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    background: #000;
    
}