.balanceContainer {
    /* backdrop-filter: blur(87.30000305175781px); */
    /* background:  var(--primary-color); */
    border-radius: 12px;
    display: flex;
    margin: 0 40px;

    align-content: center;
    justify-content: space-between;
    cursor: pointer;
    backdrop-filter: blur(100px);
    background: rgba(255, 255, 255, 0.05);
    z-index: 1;
}

.balanceContainer__balance {
    /* background: #000000; */
    border-radius: 12px;
    /* border: 0.2px solid var(--primary-color); */
    color: #fff;
    padding: 5px 30px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #fff;

    gap: 6px;
}

.balanceContainer__left {
    padding: 5px 4px 5px 10px;
    width: 35px;

    display: flex;
    align-items: center;
    /* background: var(--primary-color);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; */
}

.balanceContainer__right {
    padding: 5px 10px 5px 4px;
    width: 35px;

    display: flex;
    align-items: center;
}