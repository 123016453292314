.balanceContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-top: 10vh;
}

.balanceContainer .imageContainer {
    display: flex;
    overflow: hidden;
}

.notConnected {
    font-size: 64px;
    width: 100%;
    animation: rotate 5s infinite;
}

.balanceContainer .image {
    width: 50vw;
    height: auto;
    object-fit: contain;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateX(-50%);
    animation: rotate 5s infinite;
    z-index: 0;
}

@keyframes rotate {
    0% {
        transform: translateY(-25%) translateX(-50%) rotate(0deg);
    }

    50% {
        transform: translateY(-25%) translateX(-50%) rotate(10deg);
    }

    100% {
        transform: translateY(-25%) translateX(-50%) rotate(0deg);
    }
}

.balanceContainer .balance {
    text-align: center;
}

.balanceContainer .balance .title {
    font-size: 12px;
    color: #b4b4b4;
}

.balanceContainer .balance .value {
    font-size: 30px;
}




.fastValues {
    display: flex;
    gap: 10px;
    padding: 40px 0 6px 28vw;
}

.fastValues .value {
    color: #fff;
    background: rgb(231, 224, 174, 0.2);
    border: 0.6px solid #E7E0AE;
    padding: 4px 16px;
    flex: 1;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.withdrawButton {
    bottom: 22px;
    display: flex;
    z-index: 2;
}

.withdrawButton .button {
    background: #E7E0AE;
    color: #000;
}

.walletAddress {
    color: #fff;
}

.error {
    color: #ec0004;
    display: flex;
    align-items: center;
    gap: 12px;

    background: rgb(236, 0, 4, 0.1);
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 12px;
    margin-top: 20px;
    font-weight: 400;
    z-index: 5;
    line-height: 160%;
}

.success {
    color: #7ed321;
    display: flex;
    gap: 12px;

    background: rgb(126, 211, 33, .1);
    padding: 12px 16px;
    border-radius: 8px;

    margin-top: 20px;
    font-weight: 300px;
    z-index: 5;
}

.disconnect {
    color: #b4b4b4;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 4px;
    text-align: end;
    cursor: pointer;
}

.withdrawhs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.withdrawhs::-webkit-scrollbar {
    display: none;
}















/* dddd */
.depositTab {
    margin-top: 20px;
    padding: 0;
    overflow-y: scroll;
    scrollbar-color: var(--primary-color) #000;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
}

.depositTab::-webkit-scrollbar {
    overflow-y: scroll;
    scrollbar-color: #007 #bada55;
}

.bannerTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 133%;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 4px;
}

.bannerTitle span {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* background: var(--bg-secondary-color); */
    padding: 20px 16px;
    border-radius: 8px;
}

.line {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;
}

.icon {
    position: relative;
    width: 40px;
    height: 40px;
    background: #333335;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: var(--primary-color);
    z-index: 1;
    overflow: visible;
    font-weight: 700;
    font-size: 18px
}

/* 
.icon::after {
    content: '';
    position: fixed;
    left: 50%;
    bottom: -100%;
    width: 2px;
    height: 100%;
    background: #333335;
} */

/* .line:last-child .icon::after {
    display: none;
}


.icon::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 2px;
    height: 100%;
    background: #333335;
    z-index: 0;
}

.line:first-child .icon::before {
    display: none;
} */



.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #fff;
}

.description {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #b4b4b4;
}

.highlight {
    color: #FFE03B;
    background: #272727;
    border-radius: 4px;
    font-weight: 600;
    padding: 4px 3px;

    white-space: nowrap;
    flex-shrink: 0;
}

.buyBanner {
    background: var(--bg-secondary-color);
    padding: 24px 40px 20px;
    border-radius: 16px;
    /* padding-left: 40%; */
    position: relative;
    overflow: hidden;
    flex-shrink: 0;


    /* border: 1px solid #E7E0AE; */
    /* background: #e7e0ae21; */
}

.buyTitle {
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 16px;
    /* color: #E7E0AE; */
}



.buyBanner .image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
}

.buyBanner .buyButton {
    background: #E7E0AE;
    color: #000;
}

.stepButton {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}

.deposit_main {
    /* flex: 1; */
    background: var(--bg-secondary-color);
    padding: 20px 16px 10px;
    border-radius: 16px;
}

.depositTitle {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 18px
}

/* .depositInput {
    position: relative;
}

.depositAmountButtons {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
    margin-bottom: 4px;
    position: absolute;
    top: 0;
    right: 12px;
    transform: translateY(-50%);
    background: var(--bg-secondary-color);
}

.depositAmountButtons div {
    background: rgba(255, 255, 255, 0.1);
    font-size: 14px;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
} */

.depositButtons {
    display: flex;
    gap: 12px;
    margin-top: 16px;

}

.checkButton {
    font-size: 12px;
    /* background: #fff; */
    color: #fff;
    font-weight: 400;
    line-height: 110%;
    text-align: center;
    width: fit-content;
    background: rgba(255, 255, 255, 0.1);
    padding-left: 20px;
    padding-right: 20px;
}



.infoAlert {
    display: flex;
    font-size: 10px;
    gap: 16px;
    font-weight: 400;
    color: #b4b4b4;
    /* margin-bottom: 12px; */
    margin-top: 8px;
    align-items: center;
}

.infoAlert__icon {
    width: 36px;
    flex-shrink: 0;
    opacity: 60%;
}





/* swap */
.swapContainer {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* margin: 16px; */
}

.balanceInfo {
    font-size: 20px;
    margin-bottom: 8px;
}

.inputDisplay {
    font-size: 24px;
    margin: 16px 0;
    /* можно добавить бордер или фоновый цвет, если нужно */
    min-height: 32px;
}

.approxValue {
    font-size: 18px;
    color: gray;
    margin-bottom: 20px;
}

.keyboard {


    /* max-width: 270px; */
    /* или любое другое ограничение */
    gap: 20px;
    /* margin-bottom: 20px; */
    background: #1c1c1e;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 40px 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-top: 0.5px solid var(--primary-color);

    /* Добавляем плавность 300мс */
    transition: transform 0.3s ease-in-out;
    /* Исходно пусть будет "спрятана": */
    transform: translateY(100%);
    /* -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); */

}

.keyButton {
    background: none;
    /* без какого-либо заливочного background */
    color: #ccc;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    border: none;

}

/* Когда открыта */
.slideUp {
    transform: translateY(0);
}

/* Когда закрывается */
.slideDown {
    transform: translateY(100%);
}

.keyButton:hover {
    /* background-color: #f5f5f5; */
    /* Лёгкое выделение */
}

.buyButton {
    /* стили для кнопки "купить" */
    /* у вас уже есть PrimaryButton, так что можно использовать */
    width: 100%;
    /* max-width: 200px; */
    background: #E7E0AE;
    color: #000;
}

.swapContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 20px;
    gap: 8px;
    position: relative;
}

.swapTitle__container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;
}

.swapTitle {
    font-size: 18px;
    font-weight: 600;
}


.percentsContainer {
    display: flex;
    gap: 8px;
}

.percentButton {
    background: #1c1c1e;
    font-size: 14px;
    border: 0.5px solid #E7E0AE;
    padding: 8px 14px;
    border-radius: 8px;

}

.swapLine {
    display: flex;
    justify-content: space-between;
    background: #1c1c1e;
    width: 100%;
    padding: 20px 14px;
    border-radius: 8px;

}



.swapLine .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.swapLine .left .label {
    font-size: 12px;
    color: #b4b4b4;
}

.swapLine .left .value {
    font-size: 20px;
}


.comission{
    font-size: 12px;
    color: #b4b4b4;
}

.commissionLine{
    font-size: 12px;
    color: #b4b4b4;
    text-align: center;
    margin-top: 8px;
}

.swapLine .right {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-end;
    justify-content: center;
}

.swapLine .right .currency {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 16px;
}

.swapLine .right .currency img {
    width: 24px;
    height: 24px;
}

.swapLine .right .balance {
    font-size: 12px;
    color: #b4b4b4;
}

.receive {
    color: #E7E0AE;
    font-weight: 600;
}

.arrow {
    transform: rotate(180deg);
}


.swapActive {
    /* background: #E7E0AE;
    color: #000; */
}

.swapButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #0A0A0B;
    width: 40px;
    height: 40px;
    border-radius: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.6);
}