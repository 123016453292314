.lotteryItemS{
    display: flex;
    gap: 6px;
}

.lotteryItem {
    position: relative;
    width: fit-content;
    height: fit-content;
}

.lotteryItem:first-child{
    margin-top: 30px;
}

.lotteryItem:last-child{
    margin-top: 40px;
}

.lotteryItem::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    height: 40px;
    width: 40px;
    background: #A100EC;
    z-index: -1;

    filter: blur(20px);
    -webkit-filter: blur(20px);

    transform: translateX(-50%) translateY(-50%);
    opacity: 0.5;
}


@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .lotteryItem {
    position: relative;
    width: fit-content;
    height: fit-content;
    animation: float 3s ease-in-out infinite;
  }