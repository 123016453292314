.balanceContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-top: 10vh;
}

.balanceContainer .imageContainer {
    display: flex;
}

.balanceContainer .image {
    width: 50vw;
    height: auto;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateX(-50%);
    animation: rotate 5s infinite;
    z-index: 0;
}

@keyframes rotate {
    0% {
        transform: translateY(-25%) translateX(-50%) rotate(0deg);
    }

    50% {
        transform: translateY(-25%) translateX(-50%) rotate(10deg);
    }

    100% {
        transform: translateY(-25%) translateX(-50%) rotate(0deg);
    }
}

.balanceContainer .balance {
    padding-left: 28vw;
}

.balanceContainer .balance .title {
    font-size: 12px;
    color: #b4b4b4;
    
}

.balanceContainer .balance .value {
    font-size: 30px;
}

.fastValues {
    display: flex;
    gap: 10px;
    padding: 40px 0 6px 28vw;
}

.fastValues .value {
    color: #fff;
    background: rgb(231, 224, 174, 0.2);
    border: 0.6px solid #E7E0AE;
    padding: 4px 16px;
    flex: 1;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.withdrawButton {
    position: absolute;
    bottom: 22px;
    display: flex;
    width: calc(100% - 32px);
}

.withdrawButton .button {
    background: #E7E0AE;
    color: #000;
}

.walletAddress {
    color: #fff;
}

.error {
    color: #ec0004;
    display: flex;
    align-items: center;
    gap: 12px;

    background: rgb(236, 0, 4, 0.1);
    padding: 12px 16px;
    border-radius: 8px;

    margin-top: 20px;
    font-weight: 300px;
    z-index: 5;
}

.success {
    color: #7ed321;
    display: flex;
    gap: 12px;

    background: rgb(126, 211, 33, .1);
    padding: 12px 16px;
    border-radius: 8px;

    margin-top: 20px;
    font-weight: 300px;
    z-index: 5;
}

.disconnect {
    color: #b4b4b4;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 4px;
    text-align: end;
    cursor: pointer;
}

.withdrawhs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.withdrawhs::-webkit-scrollbar {
    display: none;
}

.totalMined{
    font-size: 13px;
    font-weight: 600;
}